import React from 'react'
import './About.scss'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Link } from 'react-scroll'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';


function About() {
    return (
        <>
            <div id="about" className="full-viewport dark">
                <div className="jumbo flex-page-column">
                    <Container className="center-content title-container about">
                        <Grid container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start">
                            <h1 className="wow animate__animated animate__fadeInDown animate_slower dark-title section-title">
                                <span className="wow animate__animated animate__fadeIn animate__slower">
                                    ABOUT
                                </span>
                            </h1>
                            <Link className="arrow-link"
                                to="projects"
                                spy={true}
                                smooth={true}
                                offset={-56}
                                duration={800}
                                delay={150}
                            >
                               <span className="arrow-wrap wow animate__animated animate__fadeInUp animate__slower">
                                    <KeyboardDoubleArrowDownIcon className="arrow-icon wow animate__animated animate__bounce animate__delay-1s" sx={{ fontSize: 40, fontWeight: 900, color:'#FFFFFF' }}/>
                                </span>
                            </Link>
                        </Grid>
                    </Container>
                    <Container>
                    <Grid className="about-container" container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start">
                        <h3 className="wow animate__animated animate__fadeInUp animate_slower about-text ltgrey-text">
                            <span className="">
                            Traversing the digital universe for over a decade, I've been privileged to take on a multitude of roles, each adding its own unique twist to my journey. For the past 7+ years that has included working on a multitude of projects while working at Google. 
                            </span>  
                        </h3>
                        <h3 className="wow animate__animated animate__fadeInUp animate_slower about-text ltgrey-text">
                            <span className="wow animate__animated animate__fadeIn animate_slower">
                            From serving as a technical lead to making impactful contributions as an individual, each position I've occupied has stoked the fires of my passion for crafting intuitive, engaging, and delightful digital experiences.
                            </span>  
                        </h3>
                        <h3 className="wow animate__animated animate__fadeInUp animate_slower about-text ltgrey-text">
                            <span className="">
                            Whether it's unraveling the intricacies of a complex codebase or weaving the elements of a captivating user interface, my commitment to innovation and user satisfaction remains undimmed.
                            </span>  
                        </h3>
                        
                        <h3 className="wow animate__animated animate__fadeInUp animate_slower about-text ltgrey-text">
                            <span className="wow animate__animated animate__fadeIn animate_slower"> 
                            So, if you find yourself in the labyrinth of a complex UX problem, looking for a way out, let's join forces. Together, we can chart a course towards seamless and impactful solutions.
                            </span>
                        </h3>
                        </Grid>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default About
