import { Link } from 'react-scroll';
import ListItemText from '@mui/material/ListItemText';

export function Menu({items}) {
    return (
      <nav className="nav-menu">
        <ul>
            {items.map((item) => (
                <li key={item.id}>
                    <Link
                        to={item.to}
                        activeClass="active"
                        className="nav-link"
                        isDynamic={true}
                        spy={true}
                        hashSpy={true}
                        smooth={true}
                        duration={800}
                        offset={-56}
                        delay={150}
                    > 
                        <ListItemText primary={item.name} />
                    </Link>
                </li>
            ))}
        </ul>
      </nav>
    );
}