import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Home from './components/Home/Home';
import ProjectDetailComp from './components/ProjectDetail/ProjectDetail';
import AppbarComp from './components/Appbar/Appbar';
import { LocalStorageProvider } from './LocalStorageContext';

function App() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  return (
    <LocalStorageProvider>
     <AppbarComp />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/projects/:id"  element={<ProjectDetailComp />} />
      </Routes>
    </LocalStorageProvider>
  );
}

export default App;
