import { useEffect, useRef } from 'react';

function useClickOutside(callback) {
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref.current) return;
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [callback]);

    return ref;
}

export default useClickOutside;