import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './Project.scss'
import { useNavigate } from "react-router-dom";
function ProjectCard({ project, openPanel }) {
    const navigate = useNavigate();

    const navigateToProject = () => {
        navigate(`/projects/${project.title.toLowerCase()}`);
    }
    return (
        <Card className="project-card">
            <CardMedia
                sx={{ height: 186, backgroundColor: '#eee', backgroundSize: 'contain' }}
                image={require(`../../assets/projects/${project.image}`)}
                title={project.title}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                {project.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                {project.description}
                </Typography>
            </CardContent>
            <CardActions className="actions" style={{height: '48px'}}>
                {project.portfolio && <Button onClick={navigateToProject} className="view-more" size="small" sx={{color: '#424242', "&:hover": {backgroundColor: "#eee" }}}>Learn More</Button>}
            </CardActions>
        </Card>
    );
}

export default ProjectCard;