import React, {useState, useEffect} from 'react'
import './Articles.scss'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Link } from 'react-scroll'
import ArticleCard from './ArticleCard'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';


const mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@trentonliebman?format=json";

function ArticlesComp() {
    const [articles, setArticles] = useState([]);

    const getMediumArticles = async () => {
        const response = await fetch(mediumURL);
        const data = await response.json();
        const formattedData = formatData(data);
        setArticles(formattedData);
    }

    useEffect(() => {
        getMediumArticles()
    }, []);
    // console.log(articles)
    return (
        <>
            <div id="articles" className="full-viewport dark">
                <div className="jumbo flex-page-column" >
                <Container className="center-content title-container">
                        <Grid container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start">
                            <h1 className="wow animate__animated animate__fadeInDown animate_slower dark-title section-title">
                                <span className="wow animate__animated animate__fadeIn animate__slower">
                                    ARTICLES
                                </span>
                            </h1>
                            <Link className="arrow-link"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-56}
                                duration={800}
                                delay={150}
                            >
                               <span className="arrow-wrap wow animate__animated animate__fadeInUp animate__slower">
                                    <KeyboardDoubleArrowDownIcon className="arrow-icon wow animate__animated animate__bounce animate__delay-1s" sx={{ fontSize: 40, fontWeight: 900, color: '#FFFFFF' }}/>
                                </span>
                            </Link>
                        </Grid>
                    </Container>
                    
                    <Container>
                        <Grid container
                              justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'center' }}
                              alignItems={{ xs: 'center', sm: 'center', md: 'center', lg: 'center' }}
                              spacing={{ xs: 4, sm: 4, md: 4 , lg: 4}} 
                        >
                            {articles?.map((article, index) => ( 
                                <Grid key={index} item >
                                    <ArticleCard article={article} />
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </div>
            </div>
        </>
    )
}

function formatData(data) {
    const avatar = data.feed.image;
    const items = data.items.map((item) => {
        item.avatar = avatar;
        return item;
    });
    return items;
}


export default ArticlesComp
