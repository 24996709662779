import React from 'react'
import './Contact.scss'
import Container from '@mui/material/Container';
import FooterComp from '../Footer/Footer'
// import ContactForm from './Form';
// import Modal from './Modal.js'

function ContactComp() {
    return (
        <>
            <div id="contact" className="full-viewport page-bg">
                <div className="jumbo center-page inner-contact" fluid="true">
                    <Container className="center-content-2">
                        <h1 className="wow animate__animated animate__fadeInDown animate_slower light-title contact section-title">
                            <span className="wow animate__animated animate__fadeIn animate__slower">
                                LET'S WORK TOGETHER TO BUILD YOUR NEXT PROJECT
                            </span>
                        </h1>
                        <h3 className="email-text">
                            <i className="fa fa-envelope responsiveEmail wow animate__animated animate__fadeIn animate__slower" />
                            <a className="responsiveEmail black-text wow animate__animated animate__fadeIn animate__slower"
                                href="mailto:trenton.liebman@gmail.com">
                                trenton.liebman@gmail.com
                            </a>
                        </h3>
                        {/* <ContactForm /> */}
                        <FooterComp />
                    </Container>
                </div>
            </div>
            
        </>
    )
}

export default ContactComp


