// LocalStorageContext.js
import React, { createContext, useState, useEffect } from 'react';

export const LocalStorageContext = createContext();

export const LocalStorageProvider = ({ children }) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem('password');
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  });

  useEffect(() => {
    try {
      window.localStorage.setItem('password', JSON.stringify(storedValue));
    } catch (error) {
      console.log(error);
    }
  }, [storedValue]);

  return (
    <LocalStorageContext.Provider value={{ storedValue, setStoredValue }}>
      {children}
    </LocalStorageContext.Provider>
  );
};
