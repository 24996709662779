import React from 'react'
import './HeroImage.scss'

function HeroImageComp() {
    return (
        <>
            <div className="heroIMAGE page-bg"></div>
        </>
    )
}

export default HeroImageComp
