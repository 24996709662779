import HeaderComp from '../Header/Header';
import HeroImageComp from '../HeroImage/HeroImage';
import About from '../About/About';
import ProjectComp from '../Project/Project';
import ArticlesComp from '../Articles/Articles';
// import RepoComp from '../Repo/Repo';
import ContactComp from '../Contact/Contact';

function Home() {
    return (
      <>
        
        <HeaderComp />
        <HeroImageComp />
        <About />
        <ProjectComp />
        <ArticlesComp />
        {/* <RepoComp /> */}
        <ContactComp />
      </>
    );
  }
  
  export default Home;