import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import moment from 'moment';


function ArticleCard({ article }) {
    const articleImage = extractFirstImageSrc(article.description);
    return (
        <Card className="article-card">
            <CardMedia
                sx={{ height: 186, backgroundColor: '#eee'}}
                image={articleImage}
            />
            <CardHeader
                avatar={
                <Avatar sx={{ border: '1px solid #BDBDBD'}}  aria-label="author" src={article.avatar} alt={article.author} />
                }
                title={article.author}
                subheader={moment(article.pubDate).format("MMM DD, YYYY")}
            />
            
            <CardContent sx={{ paddingTop: '0px'}}>
                <Typography gutterBottom variant="h5" component="div">
                    {toText(article.title)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {`${toText(article.description.substring(0, 260))}...`}
                    <a className="view-article" href={article.link} target="_blank" rel="noreferrer">view article</a>
                </Typography>
            </CardContent>
            {/*<CardActions>
                <Button className="view-more" size="small" sx={{color: '#424242', "&:hover": {backgroundColor: "#eee" }}}>Learn More</Button>
            </CardActions> */}
        </Card>
    );
}

function toText(node) {
    // console.log(node)
    let tag = document.createElement("div");
    tag.innerHTML = node;
    node = tag.innerText;
    return node;
}

export default ArticleCard;

export function extractFirstImageSrc(htmlString) {
    // Create a new DOM Parser
    var parser = new DOMParser();
    // Parse the string into a DOM Document
    var doc = parser.parseFromString(htmlString, 'text/html');
    // Query for the first img element
    var firstImage = doc.querySelector('img');
    // Return the src attribute of the first image, or null if no image is found
    return firstImage ? firstImage.src : null;
}