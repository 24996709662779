import React from 'react'
import './Footer.scss'
import Container from '@mui/material/Container';

function FooterComp() {
    return (
        <>
            <Container className="d-flex justify-content-center">
                <div className="footer">
                    <a className="m-3 wow animate__animated animate__fadeIn animate__slow"
                        href="https://www.linkedin.com/in/jtrenton/"
                        rel="noreferrer"
                        target="_blank"
                        aria-hidden="true"
                    >
                        <i className="fa fa-linkedin-square fa-2x button responsiveFooter black-text"></i>
                    </a>
                    <a className="m-3 wow animate__animated animate__fadeIn animate__slow"
                        href="https://github.com/jtrenton21"
                        rel="noreferrer"
                        target="_blank"
                        aria-hidden="true"
                    >
                        <i className="fa fa-github-square fa-2x button responsiveFooter black-text"></i>
                    </a>
                </div>
            </Container>
        </>
    )
}

export default FooterComp
