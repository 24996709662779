import "./IframeView.scss";

const IframeView = ({project}) => {
    return (
        <div className="google-slides-container">
            <iframe
                src={project.slideUrl}
                frameborder="0"
                title="Google Slides"
                allowfullscreen="true"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
            ></iframe>
        </div>
    )
};
export default IframeView;