import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link } from "react-scroll";
import "./Project.scss";
import ProjectCard from "./ProjectCard";
import projects from "../../content/projects.json";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
// import ProjectMotion from "../ProjectMotion/ProjectMotion";
// import { motion, useInView } from "framer-motion";

// const MotionGrid = motion(Grid, { forwardMotionProps: true });

// const cardSectionTitleVariants = {
// 	hidden: {
// 		opacity: 0,
// 	},
// 	show: {
// 		opacity: 1,
// 		transition: {
// 			duration: 0.4,
// 			delay: 0.2,
// 		},
// 	},
// };

// const arrowIconVariants = {
// 	hidden: {
// 		opacity: 0,
// 		transform: "translateY(0)",
// 	},
// 	show: {
// 		opacity: 1,
// 		transform: "translateY(100%)",
// 		transition: {
// 			duration: 1.5,
// 			repeat: Infinity,
// 			repeatType: "reverse",
// 		},
// 	},
// };

// const cardsContainerVariants = {
// 	hidden: { opacity: 0 },
// 	show: {
// 		opacity: 1,
// 		transition: {
// 			delayChildren: 0.7,
// 			staggerChildren: 0.5,
// 		},
// 	},
// };

// const cardItemVariants = {
// 	hidden: { opacity: 0, scale: 0.5 },
// 	show: {
// 		opacity: 1,
// 		scale: 1,
// 		transition: {
// 			duration: 1.4,
// 			type: "spring",
// 			damping: 5,
// 			stiffness: 70,
// 		},
// 	},
// };

function ProjectComp() {
	// const [state, setState] = useState();
    // const ref = React.useRef(null)
    // const isInView = useInView(ref)
	return (
		<>
			<div
				id="projects"
				className="full-viewport light"
			>
				<div className="jumbo flex-page-column">
					<Container className="center-content title-container">
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="flex-start"
						>
							<h1 className="wow animate__animated animate__fadeInDown animate_slower light-title section-title">
								<span className="wow animate__animated animate__fadeIn animate__slower">
									PROJECTS
								</span>
							</h1>
							<Link
								className="arrow-link"
								to="articles"
								spy={true}
								smooth={true}
								offset={-56}
								duration={800}
								delay={150}
							>
								<span className="arrow-wrap wow animate__animated animate__fadeInUp animate__slower">
									<KeyboardDoubleArrowDownIcon
										className="arrow-icon wow animate__animated animate__bounce animate__delay-1s"
										sx={{ fontSize: 40, fontWeight: 900 }}
									/>
								</span>
							</Link>
						</Grid>
					</Container>
					<Container>
						<Grid
							container
							justifyContent={{
								xs: "center",
								sm: "center",
								md: "center",
								lg: "center",
							}}
							alignItems={{
								xs: "center",
								sm: "center",
								md: "center",
								lg: "center",
							}}
							spacing={{ xs: 4, sm: 4, md: 4, lg: 4 }}
						>
							{/* <MotionGrid
								variants={cardsContainerVariants}
                                viewport={{ once: true }}
								initial="hidden"
                                ref={ref}
								animate={isInView ? "show" : "hidden"}
								item
								container
                                justifyContent={{
                                    xs: "center",
                                    sm: "center",
                                    md: "center",
                                    lg: "center",
                                }}
                                alignItems={{
                                    xs: "center",
                                    sm: "center",
                                    md: "center",
                                    lg: "center",
                                }}
                                spacing={{ xs: 4, sm: 4, md: 4, lg: 4 }}
							> */}
								{projects.map((project) => (
									<Grid
										key={project.id}
										item
									>
                                        <ProjectCard project={project} />
									{/* <MotionGrid
									key={project.id}
									 	variants={cardItemVariants}
									 	item
									 >
										<ProjectCard project={project} />
									</MotionGrid>  */}
									</Grid>
								))}
							{/* </MotionGrid> */}
						</Grid>
					</Container>
				</div>
			</div>
		</>
	);
}

export default ProjectComp;
