import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const PasswordForm = ({handleLogin}) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin(inputValue);
    setInputValue('');  // Reset the input field
  };

  return (
    <form className="password-form" onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        value={inputValue}
        placeholder="Enter password"
        type="text"
        name="password"
        autoComplete='off'
        onChange={handleChange}
      />
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </form>
  );
};

export default PasswordForm;
