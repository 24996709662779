import React, { useState } from "react";
import "./Appbar.scss";
import { Link } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
// import Divider from '@mui/material/Divider';
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Nav from "./Nav";

import navItems from "../../content/appbar-items.json";

const drawerWidth = 240;

function AppbarComp(props) {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { window } = props;
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};

	const navigateToProjects = (e) => {
		e.preventDefault();
        navigate("/#projects");
    };

	const drawer = (
		<Box
			onClick={handleDrawerToggle}
			sx={{ textAlign: "center", bgcolor: "black" }}
		>
			<List>
				{navItems.map((item) => (
					<ListItem
						key={item.id}
						disablePadding
					>
						<ListItemButton sx={{ textAlign: "center" }}>
							<Link
								to={item.to}
								activeClass="active"
								className="nav-link"
								isDynamic={true}
								spy={true}
								hashSpy={true}
								smooth={true}
								duration={800}
								offset={-56}
								delay={150}
							>
								<ListItemText primary={item.name} />
							</Link>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<>
			{!pathname.includes('projects/') && <Nav items={navItems} />}
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<AppBar
					component="nav"
					sx={{ bgcolor: "black" }}
				>
					<Toolbar>
						<Typography
							className="pointer-link"
							variant="h6"
							component="div"
							sx={{
								flexGrow: 1,
								display: "flex",
								justifyContent: "start",
								alignItems: "center",
							}}
						>
							<img
								src="../../images/TL-Logo.png"
								alt="Trenton Liebman Logo"
								className="logo"
							/>
							 {!pathname.includes('projects/') && <Link
								to="header"
								activeClass="active"
								className="navbar-brand"
								spy={true}
								smooth={true}
								offset={-56}
								duration={800}
								delay={150}
							>
								<span className="white-text">TRENTON</span>
								<span className="ltgrey-text"> LIEBMAN</span>
							</Link>}
							{pathname.includes('projects/') &&<span onClick={navigateToProjects} className="back-button"><span className="white-text">TRENTON</span>
								<span className="ltgrey-text"> LIEBMAN</span></span>}
						</Typography>
						{!pathname.includes('projects/') &&<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="end"
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: "none" } }}
						>
							<MenuIcon />
						</IconButton>}
						{!pathname.includes('projects/') && <Box sx={{ display: { xs: "none", sm: "block" } }}>
							{navItems.map((item) => (
								<Button
									key={item.id}
									sx={{ color: "#fff" }}
									variant="text"
								>
									<Link
										to={item.to}
										activeClass="active"
										className="nav-link"
										isDynamic={true}
										spy={true}
										hashSpy={true}
										smooth={true}
										duration={800}
										offset={-56}
										delay={150}
									>
										{" "}
										{item.name}
									</Link>
								</Button>
							))}
						</Box>}
					</Toolbar>
				</AppBar>

				<Box component="nav">
					<Drawer
						container={container}
						variant="temporary"
						anchor={"right"}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						sx={{
							display: { xs: "block", sm: "none" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
						}}
						PaperProps={{
							sx: { bgcolor: "black" },
						}}
					>
						{drawer}
					</Drawer>
				</Box>
			</Box>
		</>
	);
}

AppbarComp.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default AppbarComp;
