import React from 'react'
import './Header.scss'
import Container from '@mui/material/Container';
import { Link } from 'react-scroll'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

function HeaderComp() {
    return (
        <>
            <div id="header" className="full-viewport light">
                <div className="jumbo center-page" fluid="true">
                    <Container className="center-content-2">
                        <h1 className="display-4 black-text wow animate__animated animate__backInLeft section-title">
                            UX AFICIONADO
                        </h1>
                        <br />
                        {/* <h2 className="display-4 black-text wow animate__animated animate__backInRight section-subtitle">
                            WITH A TASTE FOR 
                        </h2> */}
                        {/* <br /> */}
                        <h2 className="display-4 mdgrey-text wow animate__animated animate__backInRight section-subtitle">
                            SOLVING COMPLEX PROBLEMS
                        </h2>
                        <br />
                        <Link className="arrow-link"
                            to="about"
                            spy={true}
                            smooth={true}
                            offset={-56}
                            duration={800}
                            delay={150}
                        >
                            <span className="wow animate__animated animate__fadeIn animate__slower">
                                <span className="arrow-wrap wow animate__animated animate__fadeInUp animate__slower">
                                    <KeyboardDoubleArrowDownIcon className="arrow-icon wow animate__animated animate__bounce animate__delay-1s" sx={{ fontSize: 40, fontWeight: 900 }}/>
                                </span>
                            </span>
                        </Link>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default HeaderComp
