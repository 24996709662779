import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// import Button from '@mui/material/Button';
import { LocalStorageContext } from '../../LocalStorageContext';
import PasswordForm from "./PasswordForm";
import IframeView from "../IframeView/IframeView";
// import { Link } from 'react-scroll'
import "./ProjectDetail.scss";
// import ProjectComp from '../Project/Project';
import projects from "../../content/projects.json";
// import projectInfo from "../../content/project-info.json";

function ProjectDetailComp() {
    const { storedValue, setStoredValue } = useContext(LocalStorageContext);
	const [isCorrect, setIsCorrect] = useState(false);
    const [showError, setShowError] = useState(false);
	const projectId = window.location.pathname.split("/")[2];
	// const project = projectInfo.find(
	// 	(project) => project.title.toLowerCase() === projectId
	// );
	const projectCard = projects.find(
		(project) => project.title.toLowerCase() === projectId
	);
    
    useEffect(() => {
		if (storedValue === 'openthedoor') {
			setIsCorrect(true);
		} else {
			setIsCorrect(false);
		}
	},[storedValue, isCorrect]);

    const handleLogin = (data) => {
        if (data.toLowerCase() === 'openthedoor') {
            setStoredValue(data);
            setShowError(false);
        } else {
            setShowError(true);
        }
    }
	return (
		<>
			<div
				id="projectDetail"
				className="full-viewport light"
                style={{marginTop: '80px'}}
			>
                {(!storedValue || !isCorrect) && (<div className="form-wrap">
                    <PasswordForm handleLogin={handleLogin}/>
                    {showError && <p className="error">Wrong password</p>}
                </div>)}
				{(storedValue && isCorrect) && (<div className="jumbo detail flex-page-column">
					<Container className="center-content title-container">
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="flex-start"
						>
							{/* <h1 className="wow animate__animated animate__fadeInDown animate_slower light-title section-title">
								<span className="wow animate__animated animate__fadeIn animate__slower">
									{project.title}
								</span>
							</h1>
							<img className="project-logo" src={require(`../../assets/projects/${project.logo}`)} /> */}
							<IframeView project={projectCard} />
						</Grid>
					</Container>
					<Container>{/* <ProjectComp /> */}</Container>
				</div>)}
			</div>
		</>
	);
}

export default ProjectDetailComp;
